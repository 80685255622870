@font-face {
  font-family: 'DMSans';
  src: local('DMSans'), url(./fonts/DMSans-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'DMSansBold';
  src: local('DMSans'), url(./fonts/DMSans-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Muli-Light';
  src: local('Muli-Light'), url(./fonts/Muli-Light.ttf) format('truetype');
}
*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;
}
body{
  font-family: 'Muli';
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: center;
  width: 100%;
  background: #F4EEFF ;
}

.LoadingScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  width: 85vw;
  overflow: hidden;
  -webkit-animation: fadeInLoad 1s;
  animation: fadeinLoad 1s;

}
@keyframes fadeinLoad {
    from { opacity: 0; }
    to   { opacity: 1; }
}


/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeinLoad {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.App {
  font-family: 'Muli';
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  -webkit-animation: fadein 2s;
  animation: fadein 2s;

}
@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}


/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.headerContainer {
  width: 85vw;
  display: flex;
  flex-direction: column;
}

.title {
  word-wrap: break-word;
  font-family: 'DMSansBold';
  font-size: 3.3vh;
  line-height: 1.2;
  letter-spacing: -0.01em;
  height: 30vh;
  width: 85vw;
  color: #4A4A4A;
  margin-top: 0.5vh;
  display: inline-block;
}
.titlePrize {
  word-wrap: break-word;
  font-family: 'DMSansBold';
  font-size: 4.4vh;
  line-height: 1.2;
  margin-top: 10vh;
  height: 20vh;
  width: 85vw;
  color: #4A4A4A;
  margin-top: 1vh;
  display: inline-block;
  padding-left: 8vw;
}
.wheel {
  position: absolute;
  top: 22vh;
  left: -20vh;
  width: 50%;
  height: 50%;
  /* -webkit-transform:scale(0.9);
-moz-transform:scale(0.9);
-ms-transform:scale(0.9);
transform:scale(0.9); */

}

.wheelSlideOutAnimation{
  left: -120vw;
  animation: wheelSlide 2s;
}

@keyframes wheelSlide {
  from {
    left: -20vh;
  }
  to {
    left: -120vw;
  }
}


.buttonContainer {
  justify-content: center;
  text-align: center;
}
* {
    -webkit-tap-highlight-color: transparent;
}

.footerContainer {
  height: 12%;
}
.footerSlideOutAnimation{
  margin-left: -200vw;
  animation: footerSlide 2s;
}

@keyframes footerSlide {
  from {
    margin-left: 0vw;
  }
  to {
    margin-left: -200vw;
  }
}

.rulesContainer {
  justify-content: center;
  text-align: center;
  padding: 1px;
} 
.rules {
  height: 4vh;
  font-size: 2.6vw;
  font-family: 'Muli-Light';
  color: rgba(107,96,124,0.9);
}

input[type=checkbox]
{
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.2); /* IE */
  -moz-transform: scale(1.2); /* FF */
  -webkit-transform: scale(1.2); /* Safari and Chrome */
  -o-transform: scale(1.2); /* Opera */
  transform: scale(1.2);
}


.prompt {
  font-size: 2.8vw;
  font-family: 'Muli-Light';
  color: rgb(255, 46, 0);
}

.mainbox{
  position: relative;
  width: 50vh;
  height: 50vh;
  display: inline-block;
}



.mainbox:after{
  position: absolute;
  content: '';
  width: 3.2vh;
  height: 3.2vh;
  background-size: 3.2vh;
  right: -3vh;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
}

.buttonContainer {

  opacity: 1;
  transition: opacity 300ms ease;
}

.touched {
  opacity: 0.5;
}
.btn {
  color: white;
  margin: auto;
  padding: 10px 15px;
  border: none;
  border-radius: 1.4vh;
  outline: none;
  cursor: pointer;
  font-family: 'DMSansBold';
  font-size: 15px;
  letter-spacing: -0.02em;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
  opacity: 1;
  transition: opacity 300ms ease;
}

.touched {
  opacity: 0.5;
}

.rotate1 {
 animation: rotate-keyframes1 10s cubic-bezier(0.25,0.1,0.25,1) forwards;
 display: inline-block;
}
@keyframes rotate-keyframes1 {
 from {
  transform: rotate(0deg);
 }
 to {
  transform: rotate(2620deg);
 }
}

.rotate2 {
 animation: rotate-keyframes2 10s cubic-bezier(0.25,0.1,0.25,1) forwards;
 display: inline-block;
}
@keyframes rotate-keyframes2 {
 from {
  transform: rotate(0deg);
 }
 to {
  transform: rotate(2560deg);
 }
}

.rotate3 {
 animation: rotate-keyframes3 10s cubic-bezier(0.25,0.1,0.25,1) forwards;
 display: inline-block;
}
@keyframes rotate-keyframes3 {
 from {
  transform: rotate(0deg);
 }
 to {
  transform: rotate(2525deg);
 }
}

.rotate4 {
 animation: rotate-keyframes4 10s cubic-bezier(0.25,0.1,0.25,1) forwards;
 display: inline-block;
}
@keyframes rotate-keyframes4 {
 from {
  transform: rotate(0deg);
 }
 to {
  transform: rotate(2485deg);
 }
}

.rotate5 {
 animation: rotate-keyframes5 10s cubic-bezier(0.25,0.1,0.25,1) forwards;
 display: inline-block;
}
@keyframes rotate-keyframes5 {
 from {
  transform: rotate(0deg);
 }
 to {
  transform: rotate(2440deg);
 }
}

.rotate6 {
 animation: rotate-keyframes6 10s cubic-bezier(0.25,0.1,0.25,1) forwards;
 display: inline-block;
}
@keyframes rotate-keyframes6 {
 from {
  transform: rotate(0deg);
 }
 to {
  transform: rotate(2400deg);
 }
}

.rotate7 {
 animation: rotate-keyframes7 10s cubic-bezier(0.25,0.1,0.25,1) forwards;
 display: inline-block;
}
@keyframes rotate-keyframes7 {
 from {
  transform: rotate(0deg);
 }
 to {
  transform: rotate(2345deg);
 }
}

.rotate8 {
 animation: rotate-keyframes8 10s cubic-bezier(0.25,0.1,0.25,1) forwards;
 display: inline-block;
}
@keyframes rotate-keyframes8 {
 from {
  transform: rotate(0deg);
 }
 to {
  transform: rotate(2300deg);
 }
}

.box{
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 50%;
  border: 0.7vh solid #fff;
  overflow: hidden;
  transition: all ease 0s;
}


span{
  width: 50%;
  height: 50%;
  font-family: 'DMSans';
  font-size: 2px;
  display: inline-block;
  position: absolute;
}
.span1{
  clip-path: polygon(0 92%, 100% 51%, 0 8%);
  top: 12vh;
  left: 0;
}
.span2{
  clip-path: polygon(100% 92%, 0 51%, 100% 8%);
  background-color: #e91e1e;
  top: 12vh;
  right: 0;
}
.span3{
  clip-path: polygon(50% 0%, 8% 100%, 92% 100%);
  bottom: 0;
  left: 12vh;
}
.span4{
  clip-path: polygon(50.5% 100%, 92% 0, 8% 0);
  top: 0;
  left: 12vh;
}
.span5{
  clip-path: polygon(0 92%, 100% 50%, 0 8%);
  top: 12vh;
  left: 0;
}
.span6{
  clip-path: polygon(100% 92%, 0 50.5%, 100% 8%);
  top: 12vh;
  right: 0;
}
.span7{
  clip-path: polygon(50.5% 0%, 8% 100%, 92% 100%);
  bottom: 0;
  left: 12vh;
}
.span8{
  clip-path: polygon(50% 100%, 92% 0, 8% 0);
  top: 0;
  left: 12vh;
}

/*Little bit adjust the value*/
.box1 .span3 b{
   transform: translate(-50%, -50%) rotate(-270deg);
}
 .span1 b,
  .span5 b,
 .span4 b{
  transform: translate(-50%, -50%) rotate(185deg);
}
 .span7 b{
  transform: translate(-50%, -50%) rotate(90deg);
}
.span4 b,
 .span8 b{
  transform: translate(-50%, -50%) rotate(-85deg);
}

.box2{
  width: 100%;
  height: 100%;
  transform: rotate(-135deg);
}
span b{
  line-height: 6.5vh;
  font-size: 1.4vh;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.center{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 5.5vh;
  height: 5.5vh;
  border-radius: 50%;
  border: 0.4vh solid #fff;

  color: #fff;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
}
.prizePicker{
  position: absolute;
  top: 25vh;
  left: 49vh;
  transform: translate(-50%, -50%);
  width: 10vh;
  height: 10vh;
  color: #fff;
  z-index: 4;
}

.mainbox.animate:after{
  animation: animateArrow 0.7s ease infinite;
}
#circleBack {
  position: absolute;
  width: 53vh;
  height: 53vh;
  left: -1.5vh;
  top: -1.5vh;
  border-radius: 50%;
  border: 2.2vh solid #670FF7;
  box-shadow: 0 5px 22px 0 rgba(0,0,0,0.2);
  z-index: -1;
}

.prizeLabel {
  width: 17vh;
  height: 7vh;
  z-index: 10;
  font-size: 2vh;
  font-family: 'DMSans';
  font-weight: 800;
  line-height: 1.9vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.triangle {
  width: 0;
  height: 0;
  border-left: 1vh solid transparent;
  border-right: 1vh solid transparent;
  border-bottom: 1vh solid white;
  z-index: 10;
}
.circle {
  width: 0.6vh;
  height: 0.6vh;
  border-radius: 50%;
  background-color: white;
  z-index: 9;
}

#prizeLabel1 {
  position: absolute;
  left: 16.4vh;
  top: 5.9vh;
  transform: rotate(-90deg);
}
#prizeLabel2 {
  position: absolute;
  left: 16.4vh;
  top: 37vh;
  transform: rotate(90deg);
}
#prizeLabel3 {
  position: absolute;
  left: 0.8vh;
  top: 21.6vh;
  transform: rotate(180deg);
}
#prizeLabel4 {
  position: absolute;
  left: 32vh;
  top: 21.6vh;
}
#prizeLabel5 {
  position: absolute;
  left: 27.5vh;
  top: 32.5vh;
  transform: rotate(45deg);
}
#prizeLabel6 {
  position: absolute;
  left: 27.5vh;
  top: 10.5vh;
  transform: rotate(-45deg);
}
#prizeLabel7 {
  position: absolute;
  left: 5.4vh;
  top: 10.7vh;
  transform: rotate(-135deg);
}
#prizeLabel8 {
  position: absolute;
  left: 5.5vh;
  top: 32.6vh;
  transform: rotate(-225deg);
}


#triangle1 {
  position: absolute;
  left: 33.6vh;
  top: 1vh;
  transform: rotate(22.5deg);
}

#triangle2 {
  position: absolute;
  left: 47.7vh;
  top: 15.1vh;
  transform: rotate(67.5deg);
}

#triangle3 {
  position: absolute;
  left: 47.4vh;
  top: 34.3vh;
  transform: rotate(112.5deg);
}

#triangle4 {
  position: absolute;
  left: 33.6vh;
  top: 48vh;
  transform: rotate(157.5deg);
}
#triangle5 {
  position: absolute;
  left: 14.5vh;
  top: 48.1vh;
  transform: rotate(202.5deg);
}

#triangle6 {
  position: absolute;
  left: 0.6vh;
  top: 34.2vh;
  transform: rotate(247.5deg);
}

#triangle7 {
  position: absolute;
  left: 0.6vh;
  top: 15.1vh;
  transform: rotate(292.5deg);
}

#triangle8 {
  position: absolute;
  left: 14.4vh;
  top: 1vh;
  transform: rotate(337.5deg);
}

#circle1 {
  position: absolute;
  left: 24.7vh;
  top: -1vh;
}
#circle2 {
  position: absolute;
  left: 42.9vh;
  top: 6.6vh;
}
#circle3 {
  position: absolute;
  left: 50.5vh;
  top: 24.7vh;
}
#circle4 {
  position: absolute;
  left: 43vh;
  top: 42.8vh;
}
#circle5 {
  position: absolute;
  left: 24.7vh;
  top: 50.4vh;
}
#circle6 {
  position: absolute;
  left: 6.6vh;
  top: 42.9vh;
}
#circle7 {
  position: absolute;
  left: -1vh;
  top: 24.8vh;
}
#circle8 {
  position: absolute;
  left: 6.5vh;
  top: 6.6vh;
}


#logo {
  margin-top: 15px;
  max-height: 3.5vh;
}

#logoWheelyPrize {
margin-top: 15px;
max-width: 25vw;
margin-bottom: 4vh;
margin-left: 7vw;
}
#logoPrize {
margin-top: 2vh;
max-height: 8vw;

margin-bottom: 1.5vh;
margin-left: 5vw;


}

.logoPrizeContainer {
  width: 85vw;
}

#loader {
margin-top: 35vh;
max-width: 25vw;
}
.check {
max-width: 3vh;
right: 4.5vw;
bottom: 2.5vh;
position: absolute;
}

#logoWheel {
  position: absolute;
  left: 23.25vh;
  top: 23.25vh;
  width: 3.5vh;
  height: 3.5vh;
  z-index: 9;
}




@keyframes animateArrow{
  50%{
    right: -40px;
  }
}

div#left {

}

div#right {
  height: 40vh;
  padding-left: 7vh;
  color: rgba(255, 255, 255, 0.87);
}

div#right h2 {
  font-size: 4.3vh;
  line-height: 4.5vh;
}

div#right p {
  padding: 2vh 0vh;
  color: rgba(252, 252, 252, 0.747);
  margin-bottom: 2vh;
}

input#mail-field {
  height: 44px;
  width: 350px;
  font-size: 20px;
  border: none;
  border-radius: 4px;
  font-family: 'Muli';
  padding-left: 13px;
}

button#spin-button {
  display: block;
  height: 44px;
  width: 250px;
  margin-top: 30px;
  font-size: 16px;
  font-family: 'Muli';
  font-weight: bold;
  border:none;
  border-radius: 4px;
  background-color: #ff922c;
  color: rgb(75, 40, 40);
  cursor: pointer;
  margin-top: 20px;
}


.overlay {
  position: absolute;
  transition: opacity 0.5s;
  pointer-events: none;
  height: 100vh;
  width: 100vw;
  opacity: 0;
  z-index: 99;
}

.overlay.active{
   opacity: 1;
   pointer-events: auto;
}
.overlayBackground {
  position: absolute;
  height: 100%;
  width: 100vw;
  opacity: 0.3;
  background: black;
}
.hyperLink {
  font-weight: normal;
  color: rgba(107,96,124,0.57);
  margin-left: 1vw;
}

.register {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  top: 15vh;
  left: 7.5vw;
  height: 52vh;
  width: 85vw;
  border-radius: 2vh;
  background: white;
  transition: height 1s;

}

.prizeContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  margin-bottom: 8vh;
  height: 800px;
  width: 500px;
  padding: 4vh;
  border-radius: 2vh;
  background: white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.claimExpiryDate {
  color: #5F1FB9;
  font-size: 14px;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .prizeContainer {
    width: 85vw;
    height: 67vh;
  }
}

.claimContainer {
  height: 34vh;
  margin-top: -4vh;
}

.buttonContainerClaim {
  height: 8vh;
  width: 85vw;
  margin-bottom: 15vh;
}


.prizeInfoName {
  font-family: 'DMSansBold';
  font-size: 3.4vh;
  line-height: 1.2;

  color: #5F1FB9;
  margin-top: 3vh;
}

.prizeInfoNameClaim {
  font-family: 'DMSansBold';
  font-size: 3.4vh;
  line-height: 1.2;

  color: #5F1FB9;
  margin-top: 0.5vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.prizeInfoDescription {
  font-family: 'DMSansBold';
  line-height: 1.2;
  font-size: 2vh;
  color: #4A4A4A;
  margin-top: 1.5vh;
}

.expiryDate {
  margin-top: 2.9vh;
  font-size: 2vh;
  color: #4A4A4A;
}

.prizeInfoId {
  font-family: 'DMSansBold';
  line-height: 1.2;
  color: #4A4A4A;
  margin-top: 3vh;
  font-size: 1.7vh;

}

.register.socialMedia{
  position: relative;
   height: 65vh;
}

.register.questions{
  position: relative;
  transition: height 1s, top 1s;


  top: 4vh;
   height: 82vh;
}

.smsContainer {
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 50vh;
  width: 85vw;
}
.smsContainer.after{
   transition: opacity 0.5s;
   opacity: 0;
}

.socialMediaContainer {
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 45vh;
  width: 85vw;
  transition: opacity 0.7s;
}

.socialMediaContainer.fadeIn{
   opacity: 1;
}

.socialMediaContainer.fadeout{
  opacity: 0;
}


.socialMediaText {
  width: 70vw;
  margin-bottom: 2vh;
  font-size: 2.1vh;
  font-family: 'Muli-Light';
  color: rgba(107,96,124,0.77);
}


.registerHeader {
  word-wrap: break-word;
  font-family: 'DMSansBold';
  width: 70vw;
  padding: 2vh 1.5vh 2vh 1.5vh;
  margin-top: 1vh;
  font-size: 4.5vw;
  color: #5F29B7;
  text-align: center;
}
.email {
  height: 10vh;
  margin-top: 0.5vh;
  width: 65vw;
  align-items: center;
}

.emailLabel {
  margin-left: 0.4vw;
  margin-bottom: 0.4vw;
  height: 2vh;
  font-size: 1.5vh;
  font-family: "DMSans";
  color: #4A4A4A;
}

.emailInput {
  height: 6vh;
  padding: 0.4vh;
  border-radius: 0.6vh;
  border: 2px solid #5F29B7;
  margin-bottom: 3vh;
  padding-left: 3vw;
  font-family: "DMSans";
  font-size: 16px;
  width: 65vw;
  align-items: center;
}
input::-webkit-input-placeholder {
     color: black; /*Change the placeholder color*/
     opacity: 0.2; /*Change the opacity between 0 and 1*/
}
.submitEmailContainer {
  padding: 2vh;
  margin-top: 1vh;
}

.spinWheelButtonContainer {
  margin-top: 4vh;
}

.spinWheelQuestionsButtonContainer {
  margin-top: 4vh;
}

.socialMediaLinkContainer {
  padding: 2vw;
  position: relative;
}

.tcAccepted {
  width:  67vw;
  display: flex;
  margin-bottom: 0.3vh;
  justify-content: flex-start;
  align-items: center;
}

.tcAcceptedContainer {
  height: 15vh;
}
.checkboxLabel {
  margin-left: 5px;
  font-size: 3vw;
  flex: 8;
}

input[type=checkbox]{
    flex: 1;
}

.material-checkbox {
  padding-left: 30px;

  color: #55289F;
}

.material-checkbox__image {
  box-sizing: border-box;

  width: 20px;
  height: 20px;

  margin-left: -30px;

  border: 2px solid currentColor;
  border-radius: 3px;

  transition: background-color .5s, border-color .5s;

  cursor: pointer;
}
.material-checkbox__image::before {
  box-sizing: border-box;
  background-color: transparent;
  border-radius: 50%;
  content: "";
  position: absolute;
  display: block;
  height: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transition: all .5s;
  width: auto;
}

.material-checkbox__label {
  vertical-align: middle;
  color: rgba(0,0,0,0.87);

  cursor: pointer;
}

.material-checkbox__input:focus + .material-checkbox__image::before {
  background-color: rgba(0,0,0,.12);
  left: -8px;
  top: -8px;
  right: -8px;
  bottom: -8px;
}

.material-checkbox__input:disabled + .material-checkbox__image,
.material-checkbox__input:disabled + .material-checkbox__image + .material-checkbox__label {
  cursor: default;
  color: rgba(0,0,0,0.38);
}

.material-checkbox__input:checked + .material-checkbox__image {
  background-color: #55289F;
  border-color: rgba(0,0,0,0);
}
.material-checkbox__input:checked + .material-checkbox__image.md-ink-ripple {
  background-color: #55289F;
  border-color: rgba(0,0,0,0);
}
.material-checkbox__input:checked + .material-checkbox__image::after {
  border-color: #fff;
  box-sizing: border-box;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  left: 3px;
  top: -1px;
  display: table;
  width: 6px;
  height: 12px;
  border-width: 2px;
  border-style: solid;
  border-radius: 0;
  border-top: 0;
  border-left: 0;
  content: "";
}
.material-checkbox__input:checked + .material-checkbox__image::before {
  background-color: #55289F;
}
.material-checkbox__input:checked + .material-checkbox__image.md-ink-ripple::before {
  background-color: rgba(255,82,82,0.26);
}

.material-checkbox__input:checked:disabled + .material-checkbox__image {
  background-color: rgba(0,0,0,0.38);
}
.material-checkbox__input:checked:disabled + .material-checkbox__image::before {
  display: none;
}

.material-checkbox__input_indeterminate + .material-checkbox__image::after {
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  display: table;
  width: 12px;
  height: 2px;
  border-width: 2px;
  border-style: solid;
  border-top: 0;
  border-left: 0;
  content: "";
}

.prizeInfoContainer {
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 15vh;
  width: 85vw;
}

.container {
  width: 100%;
  height: 100%;
}

.confettiContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events:none;
}

.questionsScreenContainer {
  height: 58vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 999999;
}

.questionsUnderline{
  height: 2vh;
  width: 30%;
}

.renderQuestionsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "DMSansBold";
}

.questionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 92%;
  height: 18vh;
  margin-bottom: 5px;
}

@keyframes fadeInQuestion {
  from {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.questionNameContainer {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.tagsContainer {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.tag {
  border-radius: 3.7vw;
  font-size: 3.8vw;
  padding: 4px;
  margin: 2px;
  text-align: center;
  white-space: nowrap;
  z-index: 99999;
}

.smileysContainer{
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.smiley{
  margin: 2px;
}

.finalScreenContainer{
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  pointer-events: none;
}


.finalScreenContent{
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  left: 100vw;
}
.finalScreenAnimation{
  left: 0vw;
  animation: finalScreen 2s;
}

@keyframes finalScreen {
  from {
    left: 100vw;
  }
  to {
    left: 0vw;
  }
}

.conclusionContainer{
  margin-top: 19%;
  width: 85%;
  height: 55%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: white;
  border-radius: 12px;
}
.graphicContainer{
  width: 85%;
  height: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation-timing-function: ease;
}


@keyframes spring { 
  0% { transform: scale(1) translateY(0); }
  30% { transform: scale(1) translateY(0); }

  40% { transform: scale(1.2, 0.6); }
  60% { transform: scale(0.8, 1.1) translateY(-30px); }
  75% { transform: scale(1) translateY(0); }
  100% { transform: translateY(0); }
}

.conclusionHeader{
  width: 85%;
  height: 10%;
  font-family: "DMSansBold";
  font-size: 4.2vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.conclusionSubHeader{
  width: 85%;
  height: 15%;
  font-family: "DMSansBold";
  font-size: 2vh;
  color: #868686;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.conclusionEmail{
  width: 85%;
  height: 15%;
  font-family: "DMSansBold";
  font-size: 2.3vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.conclusionQuote{
  width: 85%;
  height: 15%;
  font-family: "DMSans";
  font-style: italic;
  font-size: 2.3vh;
  color: #959595;
  text-align: center;
}
.conclusionQuoteAuthor{
  width: 85%;
  height: 15%;
  font-family: "DMSansBold";
  font-size: 2.3vh;
  color: #797979;
  text-align: right;
  padding-top: 6%;
}
.conclusionNB{
  width: 85%;
  height: 12%;
  font-family: "DMSans";
  font-size: 1.7vh;
  color: #808080;
  
  align-items: center;
}

.conclusionBold{
  color: #4A4A4A;
}


.followUsContainer{
  width: 85%;
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: auto;
}

.followUsText{
  width: 100%;
  height: 24%;
  font-family: "DMSansBold";
  font-size: 1.9vh;
  color: #6D6D6D;
  text-align: center;
}

.followUsButton{
  width: 100%;
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.followInstaIcon{
  pointer-events: none;
  width: 25px;
  height: 25px;
  filter: brightness(0) invert(1);
  position: absolute;
}

.followWeelyIcon{
  pointer-events: none;
  width: 78px;
  height: 20px;
  filter: brightness(0) invert(1);
  position: absolute;
}

.commentContainer{
display: flex;
flex-direction: column;
justify-content: space-between;
animation: fadeInComment 2.5s;
}

@keyframes fadeInComment {
  from {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.addComment{
  margin-top: 25px;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 400;
  font-family: "DMSans";

}
.commentInput{
  padding: 8px;
  font-size: 16px;
  border-radius: 10px;
  width: 100%;
  font-family: "DMSans";
  color:rgba(0, 0, 0, 0.8);
  border-style:solid;
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  .btn {width: 300px !important;}
  .rules {font-size: 1.7vh;}
  .wheel {
    margin-top: -22vh;
    width: 50vh;
    position: inherit;
    transform:scale(1.1) !important;
  }


.wheelSlideOutAnimation{
  margin-left: -170vw;
  animation: wheelSlide 2s;
}

@keyframes wheelSlide {
  from {
    margin-left: 0vh;
  }
  to {
    margin-left: -170vw;
  }
}
  .headerContainer {
    width: 40vw;
  }
  .register {
    width: 45vw;
    height: 55vh;
    left: 27.5vw;
  }
  .email {
    width: 35vw;
  }
  .emailInput {
    width: 35vw;
  }
  .tcAcceptedContainer {
    width: 35vw;
  }
  .tcAccepted {
  width: 35vw;
  margin-top: 1vh;
  }
  .checkboxLabel {
    font-size: 2vh;
  }
  .check{
    right: 6.5vw;
    bottom: 3.5vh;
    max-width: 3.5vh;
  }
  .submitEmailContainer {
    margin-top: 5vh;
  }
  .prompt {
    text-align: center;
    width: 35vw;
    font-size: 1.4vh;
  }
  .registerHeader {
    width: 35vw;
    font-size: 23px;
  }
  .socialMediaText {
    text-align: center;
    width: 35vw;
  }
  .socialMediaLinkContainer {
    padding: 1.5vh;
  }
  .title{
    width:40vw;
  }
  .tag {
    border-radius: 1.7vw;
    font-size: 1vw;
    padding: 6px;
    margin: 2px;
  }
  .conclusionContainer{
    margin-top: 8%;
    width: 50%;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: white;
    border-radius: 12px;
  }
  #logo {
    margin-top: 15px;
    max-height: 5.5vh;
  }
}